const getParam = (param: string) => {
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });

  return params?.[param];
};

const setParam = (param: string, value: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set(param, value);
  window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
};

// Exporta as funções para serem usadas em outros arquivos
export { getParam, setParam };
