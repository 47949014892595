import React, { memo } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../styles/theme'
import { Phone } from '../templates/Phone'

import '../styles/App.css'
import { WebphoneContextProvider } from '../utils/WebphoneContext'

export const App: React.FC = memo(() => {
  return (
    <ThemeProvider theme={theme}>
      <WebphoneContextProvider>
        <Phone />
      </WebphoneContextProvider>
    </ThemeProvider>
  )
})

export default App
