import React, { memo } from "react";
import { useTheme } from "styled-components";
import { Flex } from "../atoms/Flex";
import { Text } from "../atoms/Text";
import { PhoneHeader } from "../organisms/PhoneHeader";
import { PhoneKeyboard } from "../organisms/PhoneKeyboard";
import { getParam } from "../utils/getParam";

interface PhoneInterface {}

export const Phone: React.FC<PhoneInterface> = memo(() => {
	const { colors } = useTheme();

	return (
		<Flex justifyContent="center" alignItems="center" width={1} minHeight="100vh" backgroundColor={colors.grey[100]}>
			<Flex
				flexDirection="column"
				justifyContent="space-between"
				minWidth={350}
				maxWidth={414}
				minHeight={["100vh", "90vh"]}
				backgroundColor="white"
				borderRadius="10px"
			>
				<PhoneHeader />

				<Flex flex={1} flexDirection="column" px={47} height="100%">
					<PhoneKeyboard />

					<Flex mb={16} mt={[20, 43]}>
						<Text fontSize="xs" color="black">
							{`Meu ramal ${getParam("ramal") || "0000"}`}
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
});
