const breakpoints: any = ['380px', '410px', '420px']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]

export const theme = {
  colors: {
    orange: {
      100: '#FFECCC',
      200: '#FFD39A',
      300: '#FFB568',
      400: '#FF9742',
      500: '#FF6604',
      600: '#DB4A02',
      700: '#B73302',
      800: '#932001',
      900: '#7A1300',
    },
    red: {
      100: '#FCDBCA',
      200: '#FAB096',
      300: '#F07960',
      400: '#E24738',
      500: '#CF0000',
      600: '#B2000F',
      700: '#950019',
      800: '#78001F',
      900: '#630022',
    },
    green: {
      100: '#D1FCCA',
      200: '#9BFA96',
      300: '#60F065',
      400: '#38E24F',
      500: '#00CF30',
      600: '#00B23A',
      700: '#00953F',
      800: '#00783E',
      900: '#00633D',
    },
    purple: {
      100: '#CEC5F5',
      200: '#9F8FEC',
      300: '#6451C6',
      400: '#34258E',
      500: '#0A0144',
      600: '#07003A',
      700: '#050030',
      800: '#030027',
      900: '#020020',
    },
    grey: {
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#000000',
      800: '#424242',
      900: '#212121',
    },
  },
  shadows: {
    'shadow-grey-100': ' 2px 3px 10px 1px rgba(0, 0, 0, 0.25);',
  },
  fontSizes: {
    xs: 20,
    sm: 22,
    md: 30,
    lg: 38,
  },
  breakpoints,
}

export type FontSizeType = keyof typeof theme.fontSizes
