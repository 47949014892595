import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { fontSize, FontSizeProps } from 'styled-system'
import { ifProp, prop } from 'styled-tools'

interface TextInterface {
  fontSize: FontSizeProps
  color: 'white' | 'black'
  disabled?: boolean
  children: string
  invert?: boolean
}

const StdSpan = styled.span<
  { disabled?: boolean; asterisk?: boolean; invert?: boolean } & FontSizeProps
>`
  color: ${prop('color', 'white')};
  vertical-align: sub;
  ${ifProp(
    'disabled',
    css`
      opacity: 0.6;
    `,
  )}
  ${ifProp(
    'asterisk',
    css`
      height: 0.67em;
    `,
  )}
    ${ifProp(
    'invert',
    css`
      unicode-bidi: bidi-override;
      direction: rtl;
      text-overflow: ellipsis;
      text-align: center;
      overflow: hidden;
    `,
  )}

  cursor: pointer;
  user-select: none;
  ${fontSize}
`

export const Text: React.FC<TextInterface & FontSizeProps> = memo(
  ({ disabled, children, fontSize, invert, color, ...props }) => {
    return (
      <StdSpan
        color={color}
        disabled={disabled}
        fontSize={fontSize}
        asterisk={children === '*'}
        invert={invert}
        {...props}
      >
        {children}
      </StdSpan>
    )
  },
)
