import axios from "axios";
import { getParam } from "../utils/getParam";

const api = axios.create({
  baseURL: "https://voice-api.zenvia.com",
  headers: {
    Accept: "application/json",
    "Access-Token": getParam("apiKey"),
  },
  responseType: "json",
});

export default api;
