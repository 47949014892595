import React, { useContext } from "react";
import { Button } from "../atoms/Button";
import { Flex } from "../atoms/Flex";
import { Text } from "../atoms/Text";
import { Call } from "../molecules/Call";
import { CallEnd } from "../molecules/CallEnd";
import { maskPhone } from "../utils/masks";
import { useKeyboard } from "../utils/useKeyboard";
import WebphoneContext from "../utils/WebphoneContext";

interface PhoneKeyboardInterface {}

export const PhoneKeyboard: React.FC<PhoneKeyboardInterface> = () => {
	const { status, enviaDTMF } = useContext(WebphoneContext);
	const { phone, setPhone } = useKeyboard();

	const key = (index: number) => {
		if (index + 1 === 10) {
			return "*";
		}

		if (index + 1 === 11) {
			return "0";
		}

		if (index + 1 === 12) {
			return "#";
		}

		return (index + 1).toString();
	};

	const buttonPressed = (index: any) => {
		setPhone(phone.concat(key(index)));
		if (status === "conversando") {
			enviaDTMF(key(index));
		}
	};

	return (
		<Flex flexDirection="column" flex={1} justifyContent="space-between">
			<Flex flex={1} my={[20, 43]} justifyContent="center" alignItems="center" minHeight={[35, 45]}>
				<Text invert fontSize={{ _: "md", md: "lg" }} color="black" disabled={status === ""}>
					{maskPhone(phone).split("").reverse().join("")}
				</Text>
			</Flex>

			<Flex flexWrap="wrap" justifyContent="space-between">
				{Array(12)
					.fill(1)
					.map((_, index) => (
						<Flex
							key={index}
							mr={[(index + 1) % 3 === 0 ? 0 : "10%", (index + 1) % 3 === 0 ? 0 : "8%"]}
							mb={[16, 28]}
							width={[50, 65, 80]}
							height={[50, 65, 80]}
							maxWidth={80}
						>
							<div
								style={{
									width: "100%",
									height: "100%",
									borderRadius: 38,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									cursor: "pointer",
								}}
								onClick={() => buttonPressed(index)}
							>
								<Text fontSize={["md", "lg"]} color="black">
									{key(index)}
								</Text>
							</div>
						</Flex>
					))}

				<Flex flex={1} justifyContent="space-between">
					<Flex width={[50, 65, 80]} height={[50, 65, 80]}>
						<CallEnd disabled={[""].includes(status)} />
					</Flex>

					<Flex width={[50, 65, 80]} height={[50, 65, 80]} mx={["10%", "1%"]}>
						<Call disabled={!["conectado", "encerrada", ""].includes(status)} phone={phone} meuDTMF={phone} />
					</Flex>

					<Flex width={[50, 65, 80]} height={[50, 65, 80]}>
						<div
							style={{
								width: "100%",
								height: "100%",
								borderRadius: 38,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								cursor: "pointer",
							}}
							onClick={() => setPhone(phone.slice(0, -1))}
						>
							<Text fontSize={["md", "lg"]} color="black">
								{"<"}
							</Text>
						</div>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};
