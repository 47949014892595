import React, { memo, useState } from 'react'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp, prop, theme } from 'styled-tools'
import { Flex } from './Flex'

interface ButtonInterface {
  width: number | string
  height: number | string
  bgColor?: string
  disabled?: boolean
  onClick: () => unknown
  borderRadius?: number
  children: string | React.ReactNode
}

const StdFlex = styled(Flex)<{ pressed?: any; disabled?: boolean }>`
  background-color: ${prop('bgColor', theme('colors.grey.100'))};
  box-shadow: ${ifNotProp('bgColor', theme('shadows.shadow-grey-100'))};
  cursor: pointer;
  ${ifProp(
    'pressed',
    css`
      opacity: 0.8;
    `,
  )}
  ${ifProp(
    'disabled',
    css`
      opacity: 0.6;
    `,
  )}
`

export const Button: React.FC<ButtonInterface> = memo(
  ({ disabled, onClick, children, ...props }) => {
    const [pressed, setPressed] = useState<boolean>(false)

    return (
      <StdFlex
        justifyContent='center'
        alignItems='center'
        pressed={pressed}
        disabled={disabled}
        onMouseDown={(e) => {
          e.preventDefault()
          setPressed(true)
        }}
        onMouseUp={() => setPressed(false)}
        onClick={() => !disabled && onClick()}
        {...props}
      >
        {children}
      </StdFlex>
    )
  },
)
