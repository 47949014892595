import React, { memo, useContext } from "react";
import { Button } from "../atoms/Button";
import callIcon from "../assets/icons/call.svg";
import { useTheme } from "styled-components";
import WebphoneContext from "../utils/WebphoneContext";
import { Flex } from "../atoms/Flex";

interface CallInterface {
  disabled: boolean;
  phone: string;
  meuDTMF: string;
}

export const Call: React.FC<CallInterface> = memo(({ disabled, phone }) => {
  const { colors } = useTheme();
  const { call } = useContext(WebphoneContext);

  return (
    <Button
      width={1}
      height="100%"
      borderRadius={38}
      onClick={() => call(phone)}
      disabled={disabled}
      bgColor={colors.green[500]}
    >
      <Flex justifyContent="center" size={[25, 28, 43]}>
        <img src={callIcon} />
      </Flex>
    </Button>
  );
});
