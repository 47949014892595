import React from 'react'
import styled, { css } from 'styled-components'
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

interface FlexInterface
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    React.DOMAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export const Flex: React.FC<FlexInterface> = styled.div<FlexInterface>(
  flexbox,
  space,
  layout,
  color,
  border,
  css`
    display: flex;
  `,
)
