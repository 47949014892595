import React, { createContext, memo, useCallback, useEffect, useState } from "react";
import api from "../services/api";
import { getParam, setParam } from "./getParam";

declare global {
	interface Window {
		webphone: any;
	}
}

const WebphoneContext = createContext<{
	status: "conectado" | "chamando" | "reconectando" | "encerrada" | "conversando" | "";
	call: (phone: string) => unknown;
	enviaDTMF: (meuDTMF: string) => unknown;
	hangUp: () => unknown;
}>({ status: "", call: () => {}, hangUp: () => {}, enviaDTMF: () => {} });

interface WebphoneContextProviderInterface {
	children?: React.ReactNode;
}

const WebphoneContextProvider: React.FC<WebphoneContextProviderInterface> = memo(({ children }) => {
	const [status, setStatus] = useState<"conectado" | "chamando" | "reconectando" | "encerrada" | "conversando" | "">(
		""
	);
	const [webphone, setWebphone] = useState<any>(false);

	useEffect(() => {
		getWebphone();
	}, []);

	const getWebphone = async (phone: string = "", forCall: boolean = false): Promise<void> => {
		try {
			const ramal = getParam("ramal");

			if (!ramal) {
				return console.error("Ramal não encontrado");
			}

			const { data } = await api.get("/webphone", {
				params: { tipo: "hidden", ramal },
			});

			const validPhone = getValidPhone(phone);
			if (!validPhone) {
				return console.error("Nenhum número definido");
			}

			setParam("phone", validPhone);

			const url = data?.dados?.url;
			if (!url) {
				return console.error("Nenhuma URL definida");
			}

			const newScript = createAndLoadScript(url);
			newScript.addEventListener("load", () => {
				setWebphone(window?.webphone?.contentWindow);
			});
		} catch (error) {
			console.error("Erro ao obter webphone:", error);
		}
	};

	const getValidPhone = (phone: string): string | null => {
		const phoneByUrl = getParam("phone");
		return phoneByUrl || phone || null;
	};

	const createAndLoadScript = (src: string): HTMLScriptElement => {
		const script = document.createElement("script");
		script.setAttribute("src", src);
		document.body.appendChild(script);
		return script;
	};

	const call = useCallback(
		async (phone: string) => {
			if (!webphone) {
				getWebphone(phone);
			} else {
				webphone?.postMessage(
					{
						message: "chamaNumero",
						numero: phone,
					},
					"*"
				);
			}
		},
		[webphone]
	);

	const enviaDTMF = useCallback(
		(meuDTMF: string) => {
			webphone?.postMessage(
				{
					message: "enviaDTMF",
					dtmf: meuDTMF,
				},
				"*"
			);
		},
		[webphone]
	);

	const hangUp = useCallback(() => {
		webphone?.postMessage(
			{
				message: "hangup",
			},
			"*"
		);
	}, [webphone]);

	const onMessage = useCallback(
		(e: any) => {
			e.stopImmediatePropagation();

			if (webphone) {
				if (e.data.message === "status") {
					setStatus(e.data.status);

					window.top?.postMessage({ name: "status", data: e.data }, "*");

					if (e.data.status === "conectado" && getParam("phone")) {
						call(getParam("phone"));
					}

					if (e.data.status === "conversando") {
						enviaDTMF("meuDTMF");
					}
				}

				if (e.data.message === "chamada_id") {
					window.top?.postMessage(
						{
							name: "chamada_id",
							data: { chamadaId: e.data.chamada_id, telefoneDestino: "" },
						},
						"*"
					);
				}

				if (e.data.message === "status_erro") {
					window.top?.postMessage({ name: "status_erro", data: e.data }, "*");
				}

				if (e.data.message === "stats_webphone") {
					window.top?.postMessage({ name: "stats_webphone", data: e.data }, "*");
				}
				if (e.data.message === "pausou_na_fila") {
					window.top?.postMessage({ name: "pausou_na_fila", data: e.data }, "*");
				} else if (e.data.message === "despausou_na_fila") {
					window.top?.postMessage({ name: "despausou_na_fila", data: e.data }, "*");
				} else if (e.data.message === "entrou_na_fila") {
					window.top?.postMessage({ name: "entrou_na_fila", data: e.data }, "*");
				} else if (e.data.message === "saiu_da_fila") {
					window.top?.postMessage({ name: "saiu_da_fila", data: e.data }, "*");
				}
			}
		},
		[webphone]
	);

	useEffect(() => {
		if (webphone) {
			window.addEventListener("message", onMessage);
		}

		return () => {
			window.removeEventListener("message", onMessage);
		};
	}, [webphone]);

	return <WebphoneContext.Provider value={{ status, call, hangUp, enviaDTMF }}>{children}</WebphoneContext.Provider>;
});

export { WebphoneContextProvider };
export default WebphoneContext;
