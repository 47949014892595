import { useContext, useEffect, useState } from "react";
import { getParam } from "./getParam";
import WebphoneContext from "./WebphoneContext";

export const useKeyboard = () => {
	const [phone, setPhone] = useState<string>(getParam("phone") || "");
	const { status, hangUp, call, enviaDTMF } = useContext(WebphoneContext);

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			e.stopImmediatePropagation();

			if ((parseInt(e.key) >= 0 && parseInt(e.key) <= 9) || e.key === "*" || e.key === "#") {
				setPhone((old) => old.concat(e.key));
				if (status === "conversando") {
					enviaDTMF(e.key);
				}
			}

			if (e.key === "Backspace") {
				setPhone((old) => old.slice(0, -1));
			}

			if (e.key === "Enter") {
				if (status === "conversando" || status === "chamando") {
					hangUp();
				}

				if (status === "conectado" || status === "encerrada") {
					call(phone);
				}
			}
		};

		const onPaste = (e: ClipboardEvent) => {
			e.stopImmediatePropagation();

			const pastedData = e.clipboardData?.getData("text") || "";
			const sanitizedData = pastedData.replace(/[^\d*#]/g, "");

			if (sanitizedData) {
				setPhone((old) => old.concat(sanitizedData));
			}
		};

		window.addEventListener("keydown", onKeyDown);
		window.addEventListener("paste", onPaste as EventListener);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
			window.removeEventListener("paste", onPaste as EventListener);
		};
	}, [status, phone]);

	return { phone, setPhone };
};
