import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { switchProp, theme } from "styled-tools";
import { Flex } from "../atoms/Flex";
import { Text } from "../atoms/Text";
import WebphoneContext from "../utils/WebphoneContext";

interface PhoneHeaderInterface {}

const StdFlex = styled(Flex)<{
	status?: "conectado" | "chamando" | "reconectando" | "encerrada" | "conversando" | "";
}>`
	width: 100%;
	border-radius: 0 0 10px 10px;
	${switchProp("status", {
		"": css`
			background-color: ${theme("colors.orange.400")};
		`,
		conectado: css`
			background-color: ${theme("colors.orange.500")};
		`,
		encerrada: css`
			background-color: ${theme("colors.orange.500")};
		`,
		chamando: css`
			background-color: ${theme("colors.purple.500")};
		`,
		conversando: css`
			background-color: ${theme("colors.green.500")};
		`,
		reconectando: css`
			background-color: ${theme("colors.red.500")};
		`,
	})};
`;

export const PhoneHeader: React.FC<PhoneHeaderInterface> = ({ ...props }) => {
	const { status } = useContext(WebphoneContext);

	const statusDic = {
		"": "Aguardando...",
		conectado: "Conectado",
		encerrada: "Encerrado",
		chamando: "Chamando",
		conversando: "Conversando",
		reconectando: "Reconectando",
	};

	return (
		<StdFlex justifyContent="center" alignItems="center" status={status} minHeight={[34, 68]} {...props}>
			<Flex>
				<Text fontSize={["xs", "sm"]} color="white">
					{statusDic[status]}
				</Text>
			</Flex>
		</StdFlex>
	);
};
