import React, { memo, useContext } from 'react'
import { Button } from '../atoms/Button'
import callEnd from '../assets/icons/call-end.svg'
import { useTheme } from 'styled-components'
import WebphoneContext from '../utils/WebphoneContext'
import { Flex } from '../atoms/Flex'

interface CallEndInterface {
  disabled: boolean
}

export const CallEnd: React.FC<CallEndInterface> = memo(() => {
  const { colors } = useTheme()
  const { hangUp } = useContext(WebphoneContext)

  return (
    <Button
      width={1}
      height='100%'
      borderRadius={38}
      onClick={() => hangUp()}
      bgColor={colors.red[500]}
    >
      <Flex justifyContent='center' size={[12, 15, 18]}>
        <img src={callEnd} />
      </Flex>
    </Button>
  )
})
